<script setup lang="ts">
defineOptions({
  name: 'FormFinished',
});

const { stepDataRef } = useRegistrationForm();
const showErrorMessage = ref(false);

const loadMe = ref(true);
</script>

<template>
  <div v-if="loadMe" class="form-finished">
    <Ui2Notification v-if="showErrorMessage" status="error" :show-icon="true" :title="$mopI18n.t('common.error.title')">
      {{ $mopI18n.t('common.error.submit') }}
    </Ui2Notification>

    <div v-if="stepDataRef.title" class="form-finished__title">
      {{ stepDataRef.title }}
    </div>
    <div v-if="stepDataRef.subTitle" class="form-finished__subtitle mt-4">{{ stepDataRef.subTitle }}</div>

    <div class="form-finished__form mt-40" :data-component="stepDataRef.component">
      <component :is="stepDataRef.component" v-if="stepDataRef.component" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form-finished {
  flex-grow: 1;
}
.form-finished__form {
  max-width: 460px;
  width: 100%;

  @include v2-apply-upto(mobile) {
    max-width: none;
  }
}
.form-finished__title {
  @include v2-text-style('3xl', 'highlight');
}
.form-finished__subtitle {
  @include v2-text-style('sm');

  color: $color-text-body-secondary;
}
</style>
